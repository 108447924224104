<template>
  <div class="_expansion-slot" ref="expansionSlot">
    <div ref="expansionContent" class="_content">
      <slot>
        <!-- Content will be rendered here -->
      </slot>
    </div>
    <div ref="expansionLink" class="_links">
      <a class="_link" @click="togglePanelExpansion">{{
        this.isExpanded ? "View Less" : "View More"
      }}</a>
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";

export default {
  name: "ExpansionPanel",

  data: () => {
    return {
      collapseHeight: 350,
      isExpanded: false,
      expansionTL: undefined,
    };
  },
  methods: {
    initExpansionPanel() {
      console.log("initExpansionPanel");
      //const expansionSlot = this.$refs.expansionSlot;
      const expansionContent = this.$refs.expansionContent;
      const expansionLink = this.$refs.expansionLink;
      const timelineDuration = 0.25;

      this.expansionTL = gsap.timeline(
        {
          defaults: { duration: timelineDuration, ease: "linear" },
          paused: () => {
            return this.isExpanded ? false : true;
          },
        },
        "main"
      );

      this.expansionTL.fromTo(
        expansionContent,
        {
          height: () => {
            return this.collapseHeight;
          },
        },
        {
          height: () => {
            return expansionContent.scrollHeight + 60;
          },
        }
      );

      this.expansionTL.fromTo(
        expansionLink,
        {
          paddingTop: () => {
            return "80px";
          },
        },
        {
          paddingTop: () => {
            return "30px";
          },
        },
        "link-=timelineDuration"
      );
    },
    toggleLink() {
      const expansionContent = this.$refs.expansionContent;
      const expansionLink = this.$refs.expansionLink;
      // console.log(
      //   expansionContent,
      //   expansionContent.offsetHeight,
      //   expansionContent.scrollHeight
      // );
      if (
        expansionContent.scrollHeight <= this.collapseHeight ||
        window.innerWidth >= 1200
      ) {
        this.expansionTL.play();
        if (expansionLink) {
          expansionContent.style.paddingBottom = 0;
          expansionLink.style.display = "none";
        }
      } else {
        if (expansionLink) {
          expansionLink.style.display = "block";
        }
        if (!this.isExpanded) {
          this.expansionTL.reverse();
        }
      }
    },
    togglePanelExpansion() {
      //   console.log("togglePanelExpansion", this.isExpanded);
      if (this.isExpanded) {
        this.isExpanded = false;
        this.expansionTL.reverse();
        this.scrollMeTo("expansionContent");
        // this.$refs.expansionContent.scrollIntoView();
      } else {
        this.isExpanded = true;
        this.expansionTL.play();
      }
    },
    scrollMeTo(refName) {
      var element = this.$refs[refName];
      var top = element.offsetTop - 200;
      window.scrollTo(0, top);
    },
    onResize() {
      this.toggleLink();
      if (this.expansionTL) {
        this.expansionTL.invalidate();
      }
    },
  },
  mounted() {
    window.addEventListener("resize", this.onResize);
    this.initExpansionPanel();
    this.toggleLink();
  },
  beforeDestroy() {
    this.expansionTL.kill();
  },
};
</script>

<style scoped lang="scss">
._expansion-slot {
  display: grid;
  grid-template-rows: min-content;
  grid-template-columns: auto;
  align-items: start;
  overflow: hidden;
  ._content {
    // height: 350px;
    grid-area: 1/1/-1/-1;
    overflow: hidden;
    // padding-bottom: 60px;
    // border: 1px solid black;
    // z-index: 1;
  }
  ._links {
    grid-area: 1/1/-1/-1;
    align-self: end;
    // text-align: end;
    padding: 30px 0 0;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 50%
    );
  }
}
</style>
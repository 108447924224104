<template>
  <div class="_property">Property</div>
</template>

<script>
export default {
  name: "Property",
};
</script>

<style scoped lang="scss">
</style>
<template>
  <div class="_luxury-villa">
    <section class="_section__hero-wrapper">
      <section class="_section__luxury-villa--intro">
        <div class="_media">
          <picture class="_picture--1">
            <source
              media="(min-width: 1200px)"
              srcset="@/assets/images/luxury_hero_1-1200.jpg"
            />
            <source
              media="(min-width: 600px)"
              srcset="@/assets/images/luxury_hero_1-Small.jpg"
            />
            <img
              class="_image"
              src="@/assets/images/luxury_hero_1-Small.jpg"
              alt="luxury villa 1"
            />
          </picture>
          <picture class="_picture--2">
            <source
              media="(min-width: 1200px)"
              srcset="@/assets/images/luxury_hero_2-1200.jpg"
            />
            <source
              media="(min-width: 600px)"
              srcset="@/assets/images/luxury_hero_2-Small.jpg"
            />
            <img
              class="_image"
              src="@/assets/images/luxury_hero_2-Small.jpg"
              alt="luxury villa 2"
            />
          </picture>
        </div>
        <div class="_card--raised">
          <h2 class="_title">5 Bedroom Riverview Villa</h2>
          <div class="_paragraphs">
            <p class="_text">
              A space is created by the people who occupy it. The riverview
              villa generously lends itself to the guests for a memorable stay.
              This two storeyed stately looking villa consists of 4 bedrooms and
              a spacious family room. The living room on the ground floor is
              quite roomy along with a gaming room, a kitchen, a dining area and
              2 bedrooms.
            </p>
            <p class="_text">
              The panoramic view of the scenic valley is no stranger to the
              bedrooms and the balcony on the top floor. The fifth bedroom on
              the top floor is made from glass facades on 3 sides. It gives a
              180° view of the trees outside whereas the terrace opens up the
              world to you. Fill the villa up with iconic anecdotes, laughter
              and games.
            </p>
          </div>
        </div>
      </section>
    </section>

    <section class="_section__luxury-villa--grid">
      <GridGallery
        class="_grid__gallery"
        :gallery="houses[0].features"
      ></GridGallery>
      <ExpansionPanel class="_grid__expansion">
        <div class="_card__custom">
          <h2 class="_title">Experiences / Activities</h2>
          <div class="_paragraphs">
            <p class="_text">
              Enjoy privacy at the 6000 sq.ft. riverview villa
            </p>
            <p class="_text">The mansion provides room for upto 32 guests</p>
            <p class="_text">
              5 spacious yet cozy bedrooms for guests of all ages
            </p>
            <p class="_text">
              1 king-size and 1 queen size bed in 3 of the rooms
            </p>
            <p class="_text">
              A glass facade panoramic view bedroom with a queen size bed
            </p>
            <p class="_text">
              A family room with 1 king-size and 2 queen size beds
            </p>
            <p class="_text">
              For large gatherings, the loft area can fit upto 10 guests
            </p>
            <p class="_text">
              Each bedroom has an easy access to the garden/terrace
            </p>
            <p class="_text">
              Comfortable & friendly bedrooms for senior citizens
            </p>
            <p class="_text">
              Delicious home-cooked veg meals prepared by our cook
            </p>
            <p class="_text">
              Enjoy sports like table tennis, badminton and football
            </p>
            <p class="_text">
              Play a variety of indoor games such as Uno, Monopoly, etc.
            </p>
          </div>
        </div>
      </ExpansionPanel>
    </section>

    <section class="_section__luxury-villa--sleeping-arrangements">
      <h2 class="_title">Sleeping Arrangements</h2>
      <div class="_card__custom">
        <div
          class="_sleeping-arrangement__item"
          v-for="room in houses[0].rooms"
          :key="room.id"
        >
          <div class="_subtitle">{{ room.title }}</div>
          <div class="_text" v-for="detail in room.details" :key="detail">
            {{ detail }}
          </div>
        </div>
      </div>
    </section>

    <section class="_section__luxury-villa--amenities">
      <h2 class="_title">Amenities</h2>
      <div class="_card__custom">
        <div
          class="_amenities__item"
          v-for="amenity in houses[0].amenities"
          :key="amenity.id"
        >
          <img
            class="_amenities__icon"
            :src="amenity.iconPath"
            :alt="amenity.title"
          />
          <p class="_text">{{ amenity.title }}</p>
        </div>
      </div>
    </section>

    <section class="_section__slider--gallery">
      <h2 class="_title">Gallery</h2>
      <div class="_slider-content">
        <ContentSlider>
          <!-- <div class="_sliderGalleryWrapper" ref="sliderGalleryWrapper"> -->
          <picture
            class="_picture _slider__item"
            v-for="image in gallerySlider"
            :key="image.id"
          >
            <img
              loading="lazy"
              class="_image"
              :src="image.path"
              alt="Grid Gallery"
            />
            <div class="_caption">{{ image.caption }}</div>
          </picture>
        </ContentSlider>
      </div>
    </section>

    <section class="_section__testimonials">
      <h2 class="_title">Testimonials</h2>
      <div class="_slider">
        <div
          class="_card--light"
          v-for="item in this.testimonies"
          :key="item.id"
        >
          <div class="_content">
            <p class="_text">
              {{ item.comment }}
            </p>
            <div class="_rating">
              <h3 class="_rating__title">{{ item.name }}</h3>
              <div class="_rating__icons">
                <div
                  class="_rating__icon"
                  :class="{ '_rating__icon--active': i <= item.rating }"
                  v-for="i in 5"
                  :key="i"
                ></div>
              </div>
              <div class="_rating__image"></div>
            </div>
          </div>
        </div>
        <!-- <div></div> -->
      </div>
    </section>
  </div>
</template>

<script>
import GridGallery from "@/components/GridGallery.vue";
import ExpansionPanel from "@/components/ExpansionPanel.vue";
import ContentSlider from "@/components/ContentSlider.vue";
export default {
  name: "LuxuryVilla",
  metaInfo: {
    title: "5 Bedroom Riverview Villa",
    tags: "",
  },
  data() {
    return {
      testimonies: [
        {
          id: 1,
          name: "Dr. P. B. Kankaria",
          comment:
            "Had a good stay, good food, good hospitality. Would like to come again in future.",
          rating: 4,
        },
        {
          id: 2,
          name: "Deepak Shah",
          comment: "Loved the hospitality and the villa. Thank you.",
          rating: 4,
        },
        {
          id: 3,
          name: "Ameet",
          comment:
            "Property is very well maintained. Food is excellent. Overall a very pleasant and peaceful stay. Much appreciation for the service provided by the caretaker.",
          rating: 4,
        },
        {
          id: 4,
          name: "Moulik Solanki",
          comment:
            "Terrific place and amazing experience. Thank you Sachin & SaffronStays for your pleasant hospitality. A warm dhanyawaad on behalf of the entire group.",
          rating: 4,
        },
        {
          id: 5,
          name: "Ketan Patel",
          comment:
            "Excellent place and team. Falling short of words to express how much we liked it.",
          rating: 4,
        },
      ],
      houses: [
        {
          id: 1,
          name: "5Bhk Luxury Villa",
          rooms: [
            { title: "Bedroom 1", details: ["1 king bed", "1 queen bed"] },
            { title: "Bedroom 2", details: ["1 king bed", "1 queen bed"] },
            { title: "Bedroom 3", details: ["1 king bed", "1 queen bed"] },
            { title: "Bedroom 4", details: ["1 king bed", "1 queen bed"] },
            { title: "Bedroom 5", details: ["1 king bed", "1 queen bed"] },
            { title: "Loft", details: ["10 floor mattresses"] },
          ],
          features: [
            {
              id: 1,
              title: "",
              path: require("@/assets/images/luxury_features_1-Small.jpg"),
            },
            {
              id: 2,
              title: "",
              path: require("@/assets/images/luxury_features_2-Small.jpg"),
            },
            {
              id: 3,
              title: "",
              path: require("@/assets/images/luxury_features_3-Small.jpg"),
            },
            {
              id: 4,
              title: "",
              path: require("@/assets/images/luxury_features_4-Small.jpg"),
            },
            {
              id: 5,
              title: "",
              path: require("@/assets/images/luxury_features_5-Small.jpg"),
            },
            // {
            //   id: 6,
            //   title: "",
            //   path: require("@/assets/images/luxury_features_6-Small.jpg"),
            // },
          ],
          amenities: [
            {
              id: 1,
              title: "Air Conditioning",
              iconPath: require(`${"@/assets/amenity 1.svg"}`),
            },
            {
              id: 2,
              title: "Free WiFi",
              iconPath: require("@/assets/amenity 2.svg"),
            },
            {
              id: 3,
              title: "Pet Friendly",
              iconPath: require("@/assets/amenity 3.svg"),
            },
            {
              id: 4,
              title: "Vegetarian Meals (extra charges applicable)",
              iconPath: require("@/assets/amenity 4.svg"),
            },
            // {
            //   id: 5,
            //   title: "Alcohol Allowed",
            //   iconPath: require("@/assets/amenity 5.svg"),
            // },
            {
              id: 5,
              title: "Genset Available (extra charges applicable)",
              iconPath: require("@/assets/amenity 6.svg"),
            },
            {
              id: 6,
              title: "Free parking on premises",
              iconPath: require("@/assets/amenity 7.svg"),
            },
            {
              id: 7,
              title: "Long-term stays allowed",
              iconPath: require("@/assets/amenity 8.svg"),
            },
            {
              id: 8,
              title: "Towels and Toiletries",
              iconPath: require("@/assets/amenity 9.svg"),
            },
          ],
        },
      ],
      gallerySlider: [
        {
          id: 1,
          path: require("@/assets/images/luxury_gallery_1-Small.jpg"),
          caption: "Front View",
        },
        {
          id: 2,
          path: require("@/assets/images/luxury_gallery_2-Small.jpg"),
          caption: "Lawn View",
        },
        {
          id: 3,
          path: require("@/assets/images/luxury_gallery_3-Small.jpg"),
          caption: "Living Room First Floor - Overview",
        },
        {
          id: 4,
          path: require("@/assets/images/luxury_gallery_4-Small.jpg"),
          caption: "Living Room First Floor - Seating",
        },
        {
          id: 5,
          path: require("@/assets/images/luxury_gallery_5-Small.jpg"),
          caption: "Living Room First Floor - Dining",
        },
        {
          id: 6,
          path: require("@/assets/images/luxury_gallery_6-Small.jpg"),
          caption: "Bedroom 1",
        },
        {
          id: 7,
          path: require("@/assets/images/luxury_gallery_7-Small.jpg"),
          caption: "Bathroom 1",
        },
        {
          id: 8,
          path: require("@/assets/images/luxury_gallery_8-Small.jpg"),
          caption: "Bedroom 2",
        },
        {
          id: 9,
          path: require("@/assets/images/luxury_gallery_9-Small.jpg"),
          caption: "Bedroom 3",
        },
        {
          id: 10,
          path: require("@/assets/images/luxury_gallery_10-Small.jpg"),
          caption: "Living Room Ground Floor - Seating",
        },
        {
          id: 11,
          path: require("@/assets/images/luxury_gallery_11-Small.jpg"),
          caption: "Living Room First Floor - Table Tennis",
        },
        {
          id: 12,
          path: require("@/assets/images/luxury_gallery_12-Small.jpg"),
          caption: "Bedroom 4",
        },
        {
          id: 13,
          path: require("@/assets/images/luxury_gallery_13-Small.jpg"),
          caption: "Bathroom 4",
        },
        {
          id: 14,
          path: require("@/assets/images/luxury_gallery_14-Small.jpg"),
          caption: "Bedroom 5",
        },
        {
          id: 15,
          path: require("@/assets/images/luxury_gallery_15-Small.jpg"),
          caption: "Outdoor Seating",
        },
        {
          id: 16,
          path: require("@/assets/images/luxury_gallery_16-Small.jpg"),
          caption: "Indoor Games",
        },
        {
          id: 17,
          path: require("@/assets/images/luxury_gallery_17-Small.jpg"),
          caption: "Swing Area",
        },
        {
          id: 18,
          path: require("@/assets/images/luxury_gallery_18-Small.jpg"),
          caption: "Garden & River",
        },
      ],
    };
  },
  components: {
    GridGallery,
    ExpansionPanel,
    ContentSlider,
  },
};
</script>

<style scoped lang="scss">
// ToDo: Add another <style> template without scoped and move the media queries
// in there and see if the build css has multiple media queries???
._luxury-villa {
  ._section__luxury-villa--intro {
    @extend ._section__custom--overlap;
    ._media {
      display: grid;
      grid-template-rows: 1fr auto 5fr;
      grid-template-columns: 0.5fr 1.5fr 1fr 3fr;
      ._picture {
        &--1 {
          @extend ._picture;
          grid-area: 2/2/3/4;
          z-index: 1;
        }
        &--2 {
          @extend ._picture;
          grid-area: 1/3/-1/-1;
        }
      }
    }
  }
  ._section__luxury-villa--grid {
    @extend ._section__custom;
    ._grid__expansion {
      @extend ._card;
    }
  }
  ._section__luxury-villa--sleeping-arrangements {
    @extend ._section__custom;
    padding-bottom: $paddingMinimum;
    ._card__custom {
      padding: 0 $paddingMinimum;
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
    }
    ._sleeping-arrangement__item {
      flex: 0 0 160px;
      @extend ._card--outlined;
      margin: 0;
      padding: 20px 30px;
    }
  }
  ._section__luxury-villa--amenities {
    @extend ._section__custom;
    padding-bottom: $paddingMinimum;
    ._card__custom {
      padding: 0 $paddingMinimum;
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
    }
    ._amenities__item {
      @extend ._card--raised;
      padding: 20px 30px;
      flex: 1 0 300px;
      margin: 0;
      display: flex;
      gap: 10px;
    }
    ._amenities__icon {
      // justify-self: start;
      // flex-basis: 40%;
      // width: 100px;
      height: 40px;
    }
  }
  ._section__slider--gallery {
    @extend ._section__custom;
    ._slider-content {
      max-height: 300px;
    }
  }
  ._section__testimonials {
    @extend ._section__custom;
    // ._card--light {
    //   flex: 0 0 270px;
    // }
  }
}
@media screen and (max-width: 400px) {
  ._luxury-villa {
    ._section__luxury-villa--intro {
      grid-template-rows: auto 50px auto;
      ._media {
        ._picture {
          &--1 {
            grid-area: 2/1/3/4;
          }
        }
      }
    }
    ._section__luxury-villa--sleeping-arrangements {
      ._sleeping-arrangement__item {
        flex: 1 0 150px;
      }
    }
    ._section__luxury-villa--amenities {
      ._amenities__item {
        flex: 1 0 100%;
      }
    }
  }
}
@media screen and (min-width: 600px) {
  ._luxury-villa {
    ._section__luxury-villa--intro {
      grid-template-columns: 90% 1fr;
      ._media {
        grid-template-rows: 2fr 7fr 5fr;
      }
      ._card--raised {
        grid-area: 2/1/4/2;
      }
    }
    ._section__slider--gallery {
      ._slider-content {
        max-height: 400px;
      }
    }
  }
}
@media screen and (min-width: 1200px) {
  ._luxury-villa {
    ._section__luxury-villa--intro {
      grid-template-rows: 1.2fr 1fr;
      grid-template-columns: 70% 1fr;
      ._media {
        max-height: 600px;
        grid-template-rows: 2fr 6fr 5fr;
      }
      ._card--raised {
        grid-area: 2/1/4/2;
      }
    }
    ._section__luxury-villa--grid {
      grid-template-columns: 1fr 1.5fr;
      column-gap: Calc($paddingMinimum * 2);
      ._grid__gallery {
        grid-area: 1/2/2/3;
      }
    }
    ._section__luxury-villa--sleeping-arrangements {
      padding-bottom: 0;
    }
    ._section__luxury-villa--amenities {
      padding-bottom: 0;
    }
    ._section__slider--gallery {
      ._slider-content {
        max-height: 500px;
      }
    }
  }
}
</style>